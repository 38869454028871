import { useEffect, useRef, useState } from 'react'
import { SwiperSlide, Swiper } from 'swiper/react'
import { ReactComponent as Close } from '../../assets/close-onboarding.svg'
import { ReactComponent as ArrowLeftSlideShow } from '../../assets/left-slideshow-arrow.svg'
import { ReactComponent as ArrowRightSlideShow } from '../../assets/right-slideshow-arrow.svg'
import { ReactComponent as Logo } from '../../assets/wiki_logo.svg'
import BottomNavigation from '../BottomNavigation'
import Topbar from './Topbar'
import { Navigation } from 'swiper'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import Menu from './Menu'
import Legend from './Legend'
import Profile from '../../pages/Mobile/Profile'

export function SlidesPresentazioneMobile({
  setPresentazione,
  isFromPageWiki = false,
}: {
  setPresentazione: (presentazione: boolean) => void
  isFromPageWiki?: boolean
}) {
  const [activeSlide, setActiveSlide] = useState<number>(0)
  const sliderRef = useRef<any>()
  const { i18n, t } = useTranslation()
  return (
    <div
      className="w-100 h-100"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 12,
        backgroundColor: 'rgba(0,0,0,0.6)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Swiper
        slidesPerView={1}
        onSlideChange={(swiper) => {
          setActiveSlide(swiper.activeIndex)
        }}
        className="position-relative"
        // className="h-100 w-100"
        onSwiper={(swiper) => {
          sliderRef.current = swiper
        }}
        modules={[Navigation]}
        spaceBetween={20}
        navigation
        onInit={(swiper) => {
          swiper.slideTo(activeSlide, 0)
        }}
        style={{
          height: '60%',
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 'auto',
          marginBottom: 'auto',
          marginLeft: 'auto',
          marginRight: 'auto',
          //   backgroundColor: 'rgba(0,0,0,0.2)',
          //   zIndex: 12,
        }}
      >
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideMobile'}>
            <div className="d-flex justify-content-center align-items-center h-100 flex-column">
              <div>
                <Logo width={60} />
              </div>
              <div className="mt-4 TitleSlideMobile">
                {t('WIKI_LOVES_MONUMENTS')}
              </div>
              <div className="mt-5 SubtitleSlideMobile">{t('WELCOME')}!</div>
              <div className="mt-4">{t('SLIDE_1_TEXT')}</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideMobile'}>
            <div className="TitleSlideMobileWithBorder">1. {t('STATE')}</div>
            <div className="mt-3 mb-3">{t('SLIDE_2_TEXT')}</div>
            <div className="d-flex justify-content-center mt-5">
              <img src={'/slides/mobile/slide1-mobile.svg'} alt="Slide2" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideMobile'}>
            <div className="TitleSlideMobileWithBorder">2. {t('MENU')}</div>
            <div className="mt-3 mb-3">{t('SLIDE_3_TEXT')}</div>
            <div className="d-flex justify-content-center mt-5">
              <img src={'/slides/mobile/slide2-mobile.svg'} alt="Slide2" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideMobile'}>
            <div className="TitleSlideMobileWithBorder">3. {t('MAP')}</div>
            <div className="mt-3 mb-3">{t('SLIDE_4_TEXT')}</div>
            <div className="d-flex justify-content-center mt-5">
              <img src={'/slides/mobile/slide3-mobile.svg'} alt="Slide2" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideMobile'}>
            <div className="TitleSlideMobileWithBorder">
              4. {t('SEARCH_IN_LIST')}
            </div>
            <div className="mt-3 mb-3">{t('SLIDE_5_TEXT')}</div>
            <div className="d-flex justify-content-center mt-5">
              <img src={'/slides/mobile/slide4-mobile.svg'} alt="Slide2" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideMobile'}>
            <div className="TitleSlideMobileWithBorder">5. {t('LEGEND')}</div>
            <div className="mt-3 mb-3">{t('SLIDE_6_TEXT')}</div>
            <div className="d-flex justify-content-center mt-5">
              <img src={'/slides/mobile/slide5-mobile.svg'} alt="Slide2" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideMobile'}>
            <div className="TitleSlideMobileWithBorder">
              6. {t('MONUMENT_PAGE')}
            </div>
            <div className="mt-3 mb-3">{t('SLIDE_7_TEXT_MOBILE')}</div>
            <div className="d-flex justify-content-center mt-5">
              <img src={'/slides/mobile/slide6-mobile.svg'} alt="Slide2" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideMobile'}>
            <div className="TitleSlideMobileWithBorder">7. {t('LOGIN')}</div>
            <div className="mt-3 mb-3">{t('SLIDE_8_TEXT_MOBILE')}</div>
            <div className="d-flex justify-content-center mt-5">
              <img src={'/slides/mobile/slide7-mobile.svg'} alt="Slide2" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideMobile'}>
            <div className="TitleSlideMobileWithBorder">
              8. {t('PHOTO_SELECTION')}
            </div>
            <div className="mt-3 mb-3">{t('SLIDE_9_TEXT_MOBILE')}</div>
            <div className="d-flex justify-content-center mt-5">
              <img src={'/slides/mobile/slide8-mobile.svg'} alt="Slide2" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideMobile'}>
            <div className="TitleSlideMobileWithBorder">
              9. {t('PREVIEW_PHOTO')}
            </div>
            <div className="mt-3 mb-3">{t('SLIDE_8_TEXT')}</div>
            <div className="d-flex justify-content-center mt-5">
              <img src={'/slides/mobile/slide9-mobile.svg'} alt="Slide2" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideMobile'}>
            <div className="d-flex justify-content-center align-items-center h-100 flex-column">
              <div>
                <Logo width={60} />
              </div>
              <div className="mt-4 TitleSlideMobile">
                {t('WIKI_LOVES_MONUMENTS')}
              </div>
              <div className="mt-4">{t('SLIDE_9_TEXT')}</div>
              <div className="mt-5 SubtitleSlideMobile">{t('THANKS')}!</div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div
        onClick={() => {
          setPresentazione(false)
        }}
        className="button-close-slides-mobile"
      >
        <Close />
      </div>
      <div className={'pagination-container-onboarding'}>
        <ArrowLeftSlideShow
          onClick={() => {
            if (activeSlide > 0) {
              sliderRef.current?.slidePrev()
            }
          }}
          className={classNames('me-3', {
            pointer: activeSlide > 0,
          })}
          fill={
            activeSlide > 0
              ? 'var(--colori-neutri-white)'
              : 'var(--colori-neutri-gray-2)'
          }
        />
        <div className={'current-slide-onboarding'}>{activeSlide + 1} / 11</div>
        <ArrowRightSlideShow
          onClick={() => {
            if (activeSlide < 9) {
              sliderRef.current?.slideNext()
            }
          }}
          className={classNames('ms-3', {
            pointer: activeSlide < 10,
          })}
          fill={
            activeSlide < 10
              ? 'var(--colori-neutri-white)'
              : 'var(--colori-neutri-gray-2)'
          }
        />
      </div>
    </div>
  )
}

export default function Layout({ children }: { children: React.ReactNode }) {
  const [presentazione, setPresentazione] = useState<boolean>(false)
  const [show, setShow] = useState(false)
  const [infoWiki, setInfoWiki] = useState(false)
  const [modalLang, setModalLang] = useState(false)
  const [modalPaese, setModalPaese] = useState(false)
  const [legend, setLegend] = useState(false)
  const [profileOpen, setProfileOpen] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem('presentazione')) {
      setPresentazione(true)
      localStorage.setItem('presentazione', 'true')
    }
  }, [])
  return (
    <div className="h-100 w-100">
      {presentazione && (
        <SlidesPresentazioneMobile setPresentazione={setPresentazione} />
      )}

      <Topbar
        setShow={setShow}
        show={show}
        infoWiki={infoWiki}
        setInfoWiki={setInfoWiki}
        presentazione={presentazione}
        setPresentazione={setPresentazione}
        modalPaese={modalPaese}
        setModalPaese={setModalPaese}
        modalLang={modalLang}
        setModalLang={setModalLang}
      />

      <div className="block-content">{children}</div>
      <BottomNavigation setLegend={setLegend} legend={legend} />
      {show && (
        <Menu
          setMenu={setShow}
          setInfoWiki={setInfoWiki}
          setModalLang={setModalLang}
          setPresentazione={setPresentazione}
          setModalPaese={setModalPaese}
          setProfileOpen={setProfileOpen}
        />
      )}
      {legend && <Legend legend={legend} setLegend={setLegend} />}
      {profileOpen && (
        <Profile
          isOpenProfile={profileOpen}
          setIsOpenProfile={setProfileOpen}
        />
      )}
    </div>
  )
}
