import classNames from 'classnames'
import styles from './Menu.module.css'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Globe } from '../../../assets/globe.svg'
import { ReactComponent as Language } from '../../../assets/language.svg'
import { ReactComponent as Tutorial } from '../../../assets/tutorial.svg'
import { ReactComponent as LogoMenu } from '../../../assets/logo-menu.svg'
import { ReactComponent as ArrowBack } from '../../../assets/arrow-back.svg'
import { ReactComponent as LoginWhite } from '../../../assets/login-white.svg'
import { ReactComponent as Profile } from '../../../assets/profile.svg'
import { API_URL } from '../../../const'
import { useAuthActions, useAuthUser } from 'use-eazy-auth'
import { useLocation } from 'react-router-dom'
import { getActiveGeocontext } from '../../../utils'

export default function Menu({
  setModalLang,
  setPresentazione,
  setInfoWiki,
  setModalPaese,
  setMenu,
  setProfileOpen,
}: {
  setModalLang: (value: boolean) => void
  setPresentazione: (value: boolean) => void
  setInfoWiki: (value: boolean) => void
  setModalPaese: (value: boolean) => void
  setMenu: (value: boolean) => void
  setProfileOpen: (value: boolean) => void
}) {
  const { t, i18n } = useTranslation()
  const { user } = useAuthUser()
  const username = user ? user.username.replace('mw--', ' ') : ''
  const { logout } = useAuthActions()
  const { pathname, search } = useLocation()
  const activeGeocontext = getActiveGeocontext()
  return (
    <div className={styles.MenuContainer}>
      <div
        onClick={() => setMenu(false)}
        style={{
          width: '30%',
          background: 'rgba(0, 0, 0, 0.30)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
        }}
        className="vh-100"
      ></div>
      <div className={styles.Menu}>
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="pt-2">
            <div className="mb-3">
              <ArrowBack
                className={styles.ArrowBack}
                onClick={() => setMenu(false)}
              />
            </div>
            <div className={styles.TitleMenu}>
              {t('WIKI_LOVES_MONUMENTS')}
            </div>
            <div className={styles.SloganUser}>
              {t('WELCOME_BACK_TO_WLM')} {username ? `, ${username}!` : '!'}
            </div>
            <div className="mt-5">
              {username && (
                <div
                  className={styles.ListItemMenu}
                  onClick={() => {
                    setMenu(false)
                    setProfileOpen(true)
                  }}
                >
                  <Profile width={24} className="me-3" />{' '}
                  <div>
                    <div>{t('PROFILE')}</div>
                    <div className={styles.ListItemOptionSelected}>
                      {username}
                    </div>
                  </div>
                </div>
              )}
              <div
                className={classNames(styles.ListItemMenu, 'mt-3')}
                onClick={() => {
                  setMenu(false)
                  setModalPaese(true)
                }}
              >
                <Globe className="me-3" />{' '}
                <div>
                  <div>{t('STATE')}</div>
                  <div className={styles.ListItemOptionSelected}>
                    {activeGeocontext?.label}
                  </div>
                </div>
              </div>
              <div
                className={classNames(styles.ListItemMenu, 'mt-3')}
                onClick={() => {
                  setMenu(false)
                  setModalLang(true)
                }}
              >
                <Language className="me-3" />{' '}
                <div>
                  <div>{t('LANGUAGE')}</div>
                  <div className={styles.ListItemOptionSelected}>
                    {i18n.language === 'it' ? t('ITALIAN') : t('ENGLISH')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={styles.ListItemMenu}
              onClick={() => {
                setMenu(false)
                setPresentazione(true)
              }}
            >
              <Tutorial className="me-3" /> {t('APP_TUTORIAL')}
            </div>
            <div
              className={classNames('mt-3', styles.ListItemMenu)}
              onClick={() => {
                setMenu(false)
                setInfoWiki(true)
              }}
            >
              <LogoMenu className="me-3" /> {t('WHAT_IS_WLM')}
            </div>
            <div className="pt-4 border-top border-white">
              <div className="d-flex align-items-center justify-content-end">
                <div
                  className={styles.ListItemMenu}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (username) {
                      logout()
                    } else {
                      localStorage.setItem('redirectUrl', pathname + search)
                      window.location.href = `${API_URL}/oauth/oauth-login?redirect_uri=${window.location.href}`
                    }
                  }}
                >
                  <LoginWhite className="me-3" />{' '}
                  {username ? t('LOGOUT') : t('LOGIN')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
