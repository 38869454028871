import { ReactComponent as ScrapingRed } from '../../../assets/scraping-red.svg'
import { ReactComponent as Close } from '../../../assets/close.svg'
import styles from './Scraping.module.css'
import { useTranslation } from 'react-i18next'
import { getActiveGeocontext } from '../../../utils'
import { useLastScrapingGeocontext } from '../../../hooks/geocontext'
import dayjs from 'dayjs'

export default function Scraping({
  scrapingOpen,
  setScrapingOpen,
}: {
  scrapingOpen: boolean
  setScrapingOpen: (value: boolean) => void
}) {
  const { t } = useTranslation()
  const activeGeocontext = getActiveGeocontext()
  const lastScraping = useLastScrapingGeocontext(activeGeocontext?.id || 0)

  return (
    <div className={styles.ScrapingModal}>
      <div className="w-100 h-100 d-flex justify-content-center position-relative">
        <div
          className={styles.HomeContent}
          style={{
            width: 532,
            transition: 'width 0.3s ease-in-out',
          }}
        >
          <div className={styles.Close} onClick={() => setScrapingOpen(false)}>
            <Close />
          </div>
          <div className={styles.Title}>
            <ScrapingRed className="me-2" /> Scraping
          </div>
          <div className="d-flex align-items-center justify-content-between mt-4">
            <div className={styles.Desc}>
              {t('LAST_SCRAPING_PERFORMED_ON')}{' '}
              {dayjs(lastScraping.data?.updated).format('DD/MM/YYYY HH:mm')}
            </div>
            <div>
              <div className={styles.ButtonScraping}>
                {t('RESTART_SCRAPING')}
              </div>
            </div>
          </div>
          <div className="mt-5">
            {lastScraping.data?.query_status.map((status, index) => (
              <div key={index} className={styles.Status}>
                <div className={styles.StatusLabel}>{status.label}</div>
                <div className={'ms-5'}>
                  {status.done_monuments} / {status.total_monuments}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
